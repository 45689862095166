import _helper from "../../../_helper";
import { Form, Button, Input, Alert } from "reactstrap";
import React from "react";
import staticMessages from "../../../utils/static_messages";
import { Status, Classes, UserRoles, ShopType } from "../../../utils/constants";
import {
  Percentages,
  Days,
  Out_All,
  Redeem_Button,
  DSA,
  Okay,
} from "../../../utils/constants";
import Switch from "react-switch";

export const Greet = ({ name, printRequest }) => (
  <div onClick={printRequest}>
    {" "}
    {name + " "}
    {/* <ReactToPrint
        trigger={() => <i className="fa fa-print"></i>}
        content={() => componentRefernece.componentRef}
    /> */}
    {/* <ComponentToPrint ref={el => (componentRefernece.componentRef = el)} result={result} /> */}
  </div>
);
export const getMode = () => {
  return [ShopType.Classic, ShopType.SweepStakes];
};

export const getBadge = (status) => {
  return status === Status.Active
    ? Classes.SUCCESS
    : status === Status.Inactive
    ? Classes.SECONDARY
    : status === Status.Pending
    ? Classes.WARNING
    : status === Status.Banned
    ? Classes.DANGER
    : Classes.PRIMARY;
};

export const getState = (status) => {
  return status === 1
    ? Status.Active
    : status === 0
    ? Status.Inactive
    : status === 2
    ? Status.Pending
    : status === 3
    ? Status.Banned
    : Classes.PRIMARY;
};

export const seTHTml = (ndate) => {
  let arr = ndate.split(" ");
  return (
    <div className="set_div_to_split">
      {arr[0]}
      <div className="set_relative_date">{arr[1]}</div>
    </div>
  );
};

export const rand = (len) => {
  var x = "";
  for (var i = 0; i < len; i++) {
    x += Math.floor(Math.random() * 10);
  }
  return x;
};

export const getDepositeData = (row) => {
  if (
    row.original.profile &&
    row.original.profile.length > 0 &&
    row.original.profile[0].mode === "Classic"
  ) {
    return (
      <div style={textAlignRight}>
        <code id={`wxa_${row.original._id}`} rel="balance">
          {row.original.wallet[0]
            ? (Math.round(row.original.wallet[0].amount * 100) / 100).toFixed(2)
            : parseFloat(0).toFixed(2)}
        </code>
        /{" "}
        <code id={`wga_${row.original._id}`} rel="balance">
          {row.original.wallet[0].wager_bonus_amount > 0
            ? (
                Math.round(
                  (row.original.wallet[0].amount +
                    row.original.wallet[0].wager_bonus_amount) *
                    100
                ) / 100
              ).toFixed(2)
            : parseFloat(0).toFixed(2)}
        </code>
      </div>
    );
  } else {
    return (
      <div style={textAlignRight}>
        <code id={`wxa_${row.original._id}`} rel="balance">
          {row.original.wallet[0]
            ? (Math.round(row.original.wallet[0].amount * 100) / 100).toFixed(2)
            : parseFloat(0).toFixed(2)}
        </code>
        /{" "}
        <code id={`rda_${row.original._id}`} rel="balance">
          {row.original.wallet[0]
            ? (
                Math.round(row.original.wallet[0].redeemed_amount * 100) / 100
              ).toFixed(2)
            : parseFloat(0).toFixed(2)}
        </code>
      </div>
    );
  }
};

export const getOnlineStatus = (row) => {
  let wallet = row && row.original.wallet && row.original.wallet.length;
  let status = row.original.is_online
    ? true
    : wallet && row.original.wallet[0].is_fish_play
    ? true
    : false;
  return status;
};

export const closeButtonVisibility = (row) => {
  let amount = row.original.wallet[0]
    ? parseFloat(row.original.wallet[0].amount).toFixed(2)
    : parseFloat(0).toFixed(2);
  if (amount > 0) {
    return true;
  } else {
    return true;
  }
};

export const closeButtonDisaibility = (row, mode) => {
  let wallet =
    row.original && row.original.wallet.length && row.original.wallet[0];
  let retValue;
  if (mode === ShopType.SweepStakes) {
    return wallet.amount === 0
      ? false
      : wallet.amount >= 0 &&
        wallet.redeemed_amount >= 0 &&
        wallet.bounce_back_amount === 0
      ? false
      : !wallet.bounce_back_amount
      ? true
      : wallet.bounce_back_amount > 0
      ? true
      : false;
  } else return false;
};

export const getDefaultPageSize = (pageSize) => {
  if (pageSize) {
    return pageSize >= 100 ? 100 : pageSize;
  }
};

export const textAlignRight = { textAlign: "right" };
export const textAlignLeft = { textAlign: "left" };
export const textAlignCenter = { textAlign: "center" };

export const getNoOfDays = () => {
  return Days.map((item, index) => (
    <option key={index} value={index}>
      {item}
    </option>
  ));
};

export const getNoOfPercentage = () => {
  return Percentages.map((item, index) => (
    <option key={index} value={item.value}>
      {item.id}
    </option>
  ));
};

export const getDepositModalHtml = (modal_user, curNode) => {
  let type =
    modal_user &&
    modal_user.profile &&
    modal_user.profile.length &&
    modal_user.profile[0].mode === ShopType.Classic;
  let wager =
    curNode.state.login_user &&
    curNode.state.login_user.profile &&
    curNode.state.login_user.profile.length &&
    curNode.state.login_user.profile[0].is_wager_bonus_enabled;
  return (
    <div>
      <Form className="form-horizontal" onSubmit={curNode.handleDepositeSubmit}>
        <div className="modal-body">
          <div></div>
          <div className="text-center">
            <div className="control-group" align="center">
              <span id="modal-deposite-login" className="text-success lead">
                {modal_user ? _helper.SplitString(modal_user.passkey) : ""}
              </span>
            </div>
            <div className="control-group">
              <label className="control-label">
                Amount <span className="required">*</span>
              </label>
              <div className="controls amount_wrap">
                <Input
                  type="text"
                  onKeyPress={curNode.validateNumber.bind(curNode)}
                  name="deposite_amount"
                  placeholder="0.00"
                  onChange={curNode.setStateFromInput}
                  value={curNode.state.deposite_amount}
                />
                {curNode.v2.message(
                  "Amount",
                  curNode.state.deposite_amount,
                  "required|amount"
                )}
              </div>
            </div>
            {type && (
              <div>
                <h4>
                  {" "}
                  Happy Hours :{" "}
                  <span
                    style={{
                      color: curNode.state.availability ? "#5bb75b" : "#da4f49",
                    }}
                  >
                    {curNode.state.availability ? "Available" : "Not Available"}{" "}
                  </span>
                </h4>{" "}
              </div>
            )}
            {modal_user &&
            modal_user.profile.length &&
            modal_user.profile[0].mode === "Sweepstakes" ? (
              <div className="control-group">
                <label className="control-label">
                  <label>Bounceback</label>
                </label>
                <div className="controls">
                  <input
                    type="checkbox"
                    className="bounceback-margin"
                    checked={curNode.state.isChecked}
                    onChange={() => curNode.toggleChange({ check: false })}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="modal-footer">
          {type && wager ? (
            <div style={{ float: "left" }}>
              <Switch
                onChange={curNode.setWager}
                name="wager"
                checked={curNode.state.wager}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={27}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
              />
              <span
                className="text-primary"
                style={{
                  position: "absolute",
                  left: "80px",
                  bottom: "24px",
                  fontWeight: 600,
                  cursor: "pointer",
                }}
                onClick={curNode.infoWager}
              >
                Wager !?
              </span>
            </div>
          ) : null}
          <Button
            type="submit"
            size="sm"
            color="primary"
            disabled={curNode.state.isDisabled}
          >
            Deposit
          </Button>
          <Button
            type="submit"
            onClick={curNode.hideModal}
            size="sm"
            color="default"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export const getReedemedModalHtml = (modal_user, curNode) => {
  return (
    <div>
      <Form className="form-horizontal">
        <div className="modal-body">
          <div></div>
          <div className="text-center">
            <div className="control-group" align="center">
              <span id="modal-deposite-login" className="text-success lead">
                {modal_user ? _helper.SplitString(modal_user.passkey) : ""}
              </span>
            </div>
            <div className="control-group">
              <label className="control-label">
                Amount <span className="required">*</span>
              </label>
              <div className="controls amount_wrap">
                <Input
                  type="text"
                  onKeyPress={curNode.validateNumber.bind(curNode)}
                  name="redeemed_amount"
                  placeholder="0.00"
                  onChange={curNode.setStateFromInput}
                  value={curNode.state.redeemed_amount}
                />
                {curNode.v1.message(
                  "Amount",
                  curNode.state.redeemed_amount,
                  "required|amount"
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            onClick={curNode.handleRedeemSubmit}
            type="submit"
            name={Redeem_Button}
            size="sm"
            color={"primary"}
            disabled={curNode.state.isDisabled}
          >
            {" "}
            {Redeem_Button}
          </Button>
          <Button
            type="submit"
            onClick={curNode.hideModal}
            size="sm"
            color="default"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export const getErrorModalHtml = (modal_user, curNode) => {
  let balance = modal_user && _helper.updatedBalance(modal_user);

  let minutes = _helper.getMinutes(modal_user);
  let wallet = modal_user && modal_user.wallet.length && modal_user.wallet[0];
  let profile =
    curNode.props.user &&
    curNode.props.user.profile.length &&
    curNode.props.user.profile[0];
  return (
    <div>
      <div className="control-group" align="center">
        <span id="modal-deposite-login" className="text-success lead">
          {modal_user ? _helper.SplitString(modal_user.passkey) : ""}
        </span>
        <Alert color="danger" fade={false} style={{ margin: "10px 10px 20px" }}>
          {curNode.state.errorModal === 1
            ? "Error: Player has credits, first play all credits "
            : curNode.state.errorModal === 2
            ? "Warning: Error this player got credits from bonus! Please cash out first or play all credits."
            : curNode.state.errorModal === 3
            ? `Error: You cannot use BONUS yet. Try again in ${Math.ceil(
                profile.wager_bonus_time_limit - minutes
              )} minutes`
            : curNode.state.errorModal === 4
            ? "Error: This account can not be closed/locked. Please cash out first or play all credits. "
            : curNode.state.errorModal === 5
            ? `Success: Wager bonus of ${balance.wager_balance} cancelled successfully`
            : null}
        </Alert>
      </div>
      <div className="modal-footer">
        {(((wallet && wallet.amount === 0 && wallet.wager_bonus_amount === 0) ||
          (wallet &&
            wallet.amount >=
              wallet.initial_wager_deposit * profile.wager_bonus_multiplier)) &&
          minutes &&
          minutes < profile.wager_bonus_time_limit) ||
        curNode.state.fromDeposite ||
        curNode.state.errorModal === 5 ? null : (
          <Button
            type="submit"
            size="sm"
            color="danger"
            onClick={curNode.handleRedeemSubmit}
            name={Out_All}
            disabled={curNode.state.isOutAll}
          >
            Out All
          </Button>
        )}
        <Button
          type="submit"
          onClick={curNode.hideModal}
          size="sm"
          color="default"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export const getBonusModalHtml = (modal_user, curNode) => {
  let profile =
    curNode.props.user &&
    curNode.props.user.profile.length &&
    curNode.props.user.profile[0];
  return (
    <div>
      <div className="control-group" align="center">
        <div id="modal-deposite-login" className="text-danger lead p_lr_20">
          Happy Hour is not working when you use Wager bonus!
          <hr></hr>
        </div>
        <div className="align-left p_lr_20 readmore">
          Whenever you deposit an amount, you will get 100% of your deposit
          added to your balance. The credits are added automatically at the time
          of the deposit. For example if you deposit 10.00 you will get 20.00
          added to your balance.{" "}
          <span className={curNode.state.view ? null : "moreText"}>
            {`During wager bonus you cannot top up before your balance becomes ${
              profile.wager_bonus_multiplier
                ? profile.wager_bonus_multiplier
                : "0"
            }
            times the initial amount.For example if you deposit 10.00 you can
            cash out after 50. You can deposit again if balance goes bellow
            1.00. You can cancel wager bonus if you didn’t start playing. `}
          </span>
          <a onClick={curNode.setBonusState} className="more" href="#">
            {curNode.state.view ? "Read less" : "Read more..."}
          </a>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          type="submit"
          size="sm"
          color="danger"
          onClick={(e) => curNode.hideModal(e, Okay)}
          name={Okay}
        >
          {Okay}
        </Button>
      </div>
    </div>
  );
};

export const getCreatePlayerForm = (login_user, curNode) => {
  let profile =
    curNode.props.user &&
    curNode.props.user.profile.length &&
    curNode.props.user.profile[0];
  return login_user &&
    typeof login_user !== "undefined" &&
    login_user.role !== UserRoles.ADMIN ? (
    <Form
      className="form-horizontal form-vertical custom_code"
      encType="multipart/form-data"
      onSubmit={curNode.handleSubmit}
    >
      <div className="well">
        <div className="control-group">
          <label className="control-label">
            <label className="required">
              Deposit Amount <span className="required">*</span>
            </label>
          </label>
          <div className="controls">
            <Input
              type="number"
              onKeyPress={curNode.validateNumber.bind(curNode)}
              name="amount"
              placeholder="0.00"
              onChange={curNode.setStateFromInput}
              className="input-xlarge"
              value={curNode.state.amount}
            />
            {curNode.v.message(
              "amount",
              curNode.state.amount,
              "required|amount"
            )}
          </div>
        </div>

        <div className="control-group">
          <label className="control-label">
            <label>Username/Notes</label>
          </label>
          <div className="controls">
            <Input
              type="text"
              id="first_name"
              name="first_name"
              placeholder=""
              onChange={curNode.setStateFromInput}
              value={curNode.state.first_name}
            />
          </div>
        </div>
        {(profile.mode === ShopType.Classic &&
          profile.is_wager_bonus_enabled) ||
        (profile.mode === ShopType.SweepStakes &&
          curNode.state.is_bounceback_enabled) ? (
          <div className="control-group">
            <label className="control-label">
              <label>
                {profile.mode === ShopType.Classic ? "Wager" : "Bounceback"}
              </label>
            </label>
            <div className="controls">
              <input
                type="checkbox"
                checked={curNode.state.isCreateChecked}
                onChange={() => curNode.toggleChange({ check: true })}
              />
            </div>
          </div>
        ) : null}
        <div className="controls">
          <input
            className="btn btn-primary create_btn"
            type="submit"
            value="Create account"
            disabled={curNode.state.isDisabled}
          />
          {/* </Permission> */}
          <button
            className="btn"
            type="button"
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Form>
  ) : (
    ""
  );
};

export const ipvalidator = {
  validators: {
    ip: {
      message: staticMessages.IncorrectIp,
      rule: (val, params, validator) => {
        return (
          validator.helpers.testRegex(
            val,
            /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
          ) && params.indexOf(val) === -1
        );
      },
      messageReplace: (message, params) => {
        return message.replace(":values", this.helpers.toSentence(params));
      },
    },
  },
};

export const updateValidator = {
  validators: {
    ip: {
      message: staticMessages.IncorrectIp,
      rule: (val, params, validator) => {
        return (
          validator.helpers.testRegex(
            val,
            /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
          ) && params.indexOf(val) === -1
        );
      },
      messageReplace: (message, params) => {
        return message.replace(":values", this.helpers.toSentence(params));
      },
    },
    passwordSpace: {
      message: staticMessages.spacedPswd,
      rule: (val, params, validator) => {
        return (
          !validator.helpers.testRegex(val, /\s/g) && params.indexOf(val) === -1
        );
      },
    },
    passwordMin: {
      message: staticMessages.PasswordLength,
      rule: (val, params, validator) => {
        return (
          validator.helpers.size(val) >= 6 &&
          validator.helpers.size(val) > 0 &&
          params.indexOf(val) === -1
        );
      },
    },
    passwordMax: {
      message: staticMessages.PasswordMax,
      rule: (val, params, validator) => {
        return validator.helpers.size(val) <= 64 && params.indexOf(val) === -1;
      },
    },
    in: {
      message: "The :attribute is equal to password.",
      rule: (val, params) => params.includes(val),
    },
    email: {
      message: staticMessages.ValidEmail,
      rule: (val, params, validator) =>
        validator.helpers.testRegex(
          val,
          /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        ),
    },
  },
};

export const createValidator = {
  validators: {
    required: {
      message: ":attribute cannot be blank!",
      rule: (val, params, validator) => !validator.helpers.isBlank(val),
      required: true,
    },
    alpha_num: {
      message: "The :attribute may only contain letters and numbers.",
      rule: (val, params, validator) =>
        validator.helpers.testRegex(val, /^[A-Z0-9]*$/i),
    },
    usernameMax: {
      message: staticMessages.UsernameMax,
      rule: (val, params, validator) => {
        return validator.helpers.size(val) <= 50 && params.indexOf(val) === -1;
      },
    },
    ip: {
      message: staticMessages.IncorrectIp,
      rule: (val, params, validator) => {
        return (
          validator.helpers.testRegex(
            val,
            /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
          ) && params.indexOf(val) === -1
        );
      },
      messageReplace: (message, params) => {
        return message.replace(":values", this.helpers.toSentence(params));
      },
    },
    passwordSpace: {
      message: staticMessages.spacedPswd,
      rule: (val, params, validator) => {
        return (
          !validator.helpers.testRegex(val, /\s/g) && params.indexOf(val) === -1
        );
      },
    },
    passwordMin: {
      message: staticMessages.PasswordLength,
      rule: (val, params, validator) =>
        validator.helpers.size(val) >= 6 && params.indexOf(val) === -1,
    },
    passwordMax: {
      message: staticMessages.PasswordMax,
      rule: (val, params, validator) => {
        return validator.helpers.size(val) <= 64 && params.indexOf(val) === -1;
      },
    },

    in: {
      message: "The :attribute is equal to password.",
      rule: (val, params) => params.includes(val),
    },
    email: {
      message: staticMessages.ValidEmail,
      rule: (val, params, validator) =>
        validator.helpers.testRegex(
          val,
          /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        ),
    },
    balance: {
      message: staticMessages.BalanceError,
      rule: (val, params, validator) =>
        validator.helpers.testRegex(val, /^\d+(\.\d{1,2})?$/),
    },
  },
};

export const settingValidator = {
  validators: {
    passwordSpace: {
      message: staticMessages.spacedPswd,
      rule: (val, params, validator) => {
        return (
          !validator.helpers.testRegex(val, /\s/g) && params.indexOf(val) === -1
        );
      },
    },
    passwordMin: {
      message: staticMessages.PasswordLength,
      rule: (val, params, validator) =>
        validator.helpers.size(val) >= 6 && params.indexOf(val) === -1,
    },
    passwordMax: {
      message: staticMessages.PasswordMax,
      rule: (val, params, validator) => {
        return validator.helpers.size(val) <= 64 && params.indexOf(val) === -1;
      },
    },
    in: {
      message: "The :attribute is equal to password.",
      rule: (val, params) => params.includes(val),
    },
  },
};

export const amountValidator = {
  validators: {
    amount: {
      message: staticMessages.BalanceError,
      rule: (val, params, validator) => {
        return validator.helpers.testRegex(
          val,
          /^[-+]?[0-9]\d*(\.[0-9]{1,2})?$/
        );
      },
    },
  },
};

// export const amountValidator = {
//   validators: {
//     amount: {
//       message: staticMessages.BalanceError,
//       rule: (val, params, validator) => {
//         return validator.helpers.testRegex(val, /^\d+(\.\d{1,2})?$/);
//       },
//     },
//   },
// };

export const createUser = (message) => {
  let text = message.split(" ")[0];
  switch (text) {
    case "Account":
      return staticMessages.BlankType;
    case "Login":
      return staticMessages.BlankLogin;
    case "Password":
      return staticMessages.BlankPassword;
    case "Mode":
      return staticMessages.BlankMode;
    case "Time":
      return staticMessages.TimeZone;
    case "Length":
      return staticMessages.PasswordLength;
    case "BackSlash":
      return staticMessages.UsernameBS;
    case "Email":
      return staticMessages.ValidEmail;
    default:
      return message;
  }
};

export const getMinMax = (userBounceBackArr, userBounceBackTypeArr) => {
  let add = [];
  userBounceBackArr.forEach((element, index) => {
    if (element.value < 0) {
      add = "Bounceback limit must be greater than 0";
    } else {
      userBounceBackTypeArr.forEach((i_element) => {
        if (element.id === i_element.id) {
          if (i_element.value === 1) {
            if (index === 0 && element.value > 20)
              add.push(
                " Bounceback limit can be max 20 % for limit 5 ... 19.99"
              );
            else if (index === 1 && element.value > 25)
              add.push(
                "Bounceback limit can be max 25 % for limit 20 ... 49.99"
              );
            else if (index === 2 && element.value > 30)
              add.push(
                "Bounceback limit can be max 30 % for limit 50 ... 99.99"
              );
            else if (index === 3 && element.value > 35)
              add.push(
                " Bounceback limit can be max 35 % for limit 100 ... INF"
              );
          } else {
            if (index === 0 && element.value > 2)
              add.push(
                "Bounceback limit can be max 2 USD for limit 5 ... 19.99"
              );
            else if (index === 1 && element.value > 5)
              add.push(
                "Bounceback limit can be max 5 USD for limit 20 ... 49.99"
              );
            else if (index === 2 && element.value > 10)
              add.push(
                " Bounceback limit can be max 10 USD for limit 50 ... 99.99"
              );
            else if (index === 3 && element.value > 15)
              add.push(
                "Bounceback limit can be max 15 USD for limit 100 ... INF"
              );
          }
        }
      });
    }
  });
  return add;
};

export const getLanguageList = (data) => {
  let list = [];
  data &&
    data.length &&
    data.map((item) => {
      list.push(
        <option key={item._id} value={item._id}>
          {item.title}
        </option>
      );
    });

  return list;
};

export const getTimeZones = (data) => {
  let list = [];
  data &&
    data.length &&
    data.map((item) => {
      list.push(
        <option key={item._id} value={item._id}>
          {item.title}
        </option>
      );
    });

  return list;
};

export const redirectLocation = (role) => {
  switch (role) {
    case UserRoles.ADMIN:
      return "/users";
    case UserRoles.SHOP:
      return "/players";
    case UserRoles.DISTRIBUTOR:
      return "/users";
    case UserRoles.CASHIER:
      return "/players";
    default:
      return false;
  }
};

export const logsList = (log) => {
  switch (log) {
    case "Create Account":
      return true;
    case "Redeemed":
      return true;
    case "reverse":
      return true;
    case "Deposit":
      return true;
    case "internet_time_buy":
      return true;
    case "internet_time_buy_all":
      return true;
    default:
      return false;
  }
};

export const logsColor = (log) => {
  switch (log) {
    case "game_bonus_spin":
      return true;
    case "game_bonus_feature_pick":
      return true;
    case "game_bonus_start":
      return true;
    case "game_bonus_start_more":
      return true;
    case "game_bonus_end":
      return true;
    case "game_feature_minigame":
      return true;

    default:
      return false;
  }
};

export const NoDataComponent = (loading) => {
  let component = loading ? null : (
    <div className="rt-noData">No rows found</div>
  );
  return component;
};
