import axios from "axios";
import config from "./config";
import { localStorageKeys, authKeys } from "../utils/constants";
import { UNAUTH_USER } from "./types";
axios.defaults.baseURL = config.baseURL;

if (localStorage.getItem(localStorageKeys.accessToken)) {
  axios.defaults.headers.common[authKeys.authorization] = `${
    authKeys.apiHeaderTokenPrefix
  } ${localStorage.getItem(localStorageKeys.accessToken)}`;
}
axios.defaults.headers.post["Content-Type"] = "application/json";

const configs = () => {
  let headerObj = {};
  headerObj[authKeys.authorization] = `${
    authKeys.apiHeaderTokenPrefix
  } ${localStorage.getItem(localStorageKeys.accessToken)}`;
  return headerObj;
};

/**
 * Get request to get the data
 * @param url
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const get = ({ url, type, errorMessage, nextDispatch }) => (dispatch) =>
  axios
    .get(url, configs())
    .then((response) => {
      let returnData = response.data ? response.data : response.message;
      dispatch({
        type: type ? type : null,
        payload: returnData,
      });
      let a = nextDispatch
        ? dispatch({ type: nextDispatch, payload: response.data.data })
        : null;
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type:
          error.response.status === 401
            ? UNAUTH_USER
            : errorMessage
            ? type
            : null,
        payload: errorMessage ? errorMessage : null,
      });
      return error.response.data;
    });

/**
 * Delete data
 * @param url
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const deleteData = ({ url, type }) => (dispatch) =>
  axios
    .delete(url, configs())
    .then((response) => {
      let returnData = response.data ? response.data : response.message;
      dispatch({ type, payload: returnData });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: error.response.status === 401 ? UNAUTH_USER : type,
        payload: error.response.data,
      });
      return error.response.data;
    });

/**
 * Put request to update the data
 * @param url
 * @param payload
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const put = ({ url, payloads, type, typeError, redirectsTo }) => (
  dispatch
) =>
  axios
    .put(url, payloads, configs())
    .then((response) => {
      let returnData = response.data ? response.data : response.message;
      dispatch({
        type: type ? type : null,
        payload: returnData,
      });

      if (redirectsTo) {
        window.location = redirectsTo;
      }
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type:
          error.response.status === 401
            ? UNAUTH_USER
            : typeError
            ? typeError
            : type,
        payload: error.response.data,
      });
      return error.response.data;
    });

/**
 * Post request
 * @param url
 * @param payload
 * @param type
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const post = ({ url, payloads, type, errorType, redirectsTo }) => (
  dispatch
) =>
  axios
    .post(url, payloads, configs())
    .then((response) => {
      let returnData = response.data ? response.data : response.message;
      dispatch({
        type: type ? type : null,
        payload: returnData,
      });
      if (redirectsTo) {
        window.location = redirectsTo;
      }
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: error.response.status === 401
        ? UNAUTH_USER : errorType ? errorType : type,
        payload: error.response.data,
      });
      return error.response.data;
    });
