import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";


// Containers
import { DefaultLayout } from "./containers";

// Pages
import { Login, Logout, Page404, Page500, Register } from "./views/Pages";

import RequireAuth from "./components/auth/require_auth";
import reducers from "./reducers";
import { AUTH_USER } from "./actions/types";
import reduxThunk from "redux-thunk";

// import { renderRoutes } from 'react-router-config';
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(reducers);
const token = localStorage.getItem("auth_jwt_token");
let secret = token && JSON.parse(atob(token.split(".")[1]));

// if we have a token, consider the user to be signed in
if (token && new Date() < new Date(parseInt(secret.exp) * 1000)) {
    store.dispatch({ type: AUTH_USER });
}

class App extends Component {

 

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={RequireAuth( Login)} />
            <Route exact path="/logout" name="Logout Page" component={Logout} />
            <Route
              exact
              path="/register"
              name="Register Page"
              component={Register}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route
              path="/"
              name="Home"
              component={RequireAuth(DefaultLayout)}
            />
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
