import config from "./config";
import { get, post, put, deleteData } from "./http";
import { JWT_TOKEN, ROLE, SUPER_ADMIN, UserRoles } from "../utils/constants";
import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  TRY_CONNECT,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE_GOOD,
  UPDATE_USER_PROFILE_FAIL,
  USER_LIST,
  USER_DELETE,
  USER_CREATE,
  DASHBOARD,
  PLAYERS_LIST,
  CREATE_PLAYER,
  CHECK_USER,
  WALLET_CREATE,
  LANGUAGE_INDEX,
  BOUNCEBACK_LIST,
  GAME_LOG_INDEX,
  GAME_LIST
} from "./types";

const axios = config.axios;

if (localStorage.getItem(JWT_TOKEN)) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem(JWT_TOKEN);
} else {
  // console.error("Not Getting JWT_TOKEN");
}
axios.defaults.headers.post["Content-Type"] = "application/json";

const inValidToken = () => {
  localStorage.removeItem(JWT_TOKEN);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(SUPER_ADMIN);
  window.location = "/login";
};

export const logUserOut = inValidToken;

export const language_index = () => {
  return get({
    url: `languages/index`,
    type: LANGUAGE_INDEX
  });
};

export const signUserIn = data => dispatch =>
  axios
    .post(`users/authenticate`, data)
    .then(res => {
      dispatch({
        type: AUTH_USER,
        payload: res.data
      });
      localStorage.setItem(JWT_TOKEN, res.data.data.token);
      localStorage.setItem(ROLE, res.data.data.role);
      localStorage.setItem(
        SUPER_ADMIN,
        res.data.data.userWithoutHash.is_superadmin
      );

      if (res.data.data.role === UserRoles.SHOP) {
        window.location = "/players";
      } else if (res.data.data.role === UserRoles.DISTRIBUTOR) {
        window.location = "/users";
      } else {
        window.location = "/";
      }
      axios.defaults.headers.common["Authorization"] = localStorage.getItem(
        JWT_TOKEN
      );
    })
    .catch(error => {
      let errorResponse = (error.response || {}).data;
      dispatch({ type: AUTH_ERROR, payload: errorResponse });
    });

export const signUserUp = (userObj, formData) => {
  return post({
    url: `users/register`,
    payloads: formData,
    type: USER_CREATE,
    errorType: AUTH_ERROR,
    redirectsTo: "/users"
  });
};

export const signUserOut = ()=> {
  return (dispatch) =>{
    dispatch({ type: UNAUTH_USER });
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(ROLE);
    localStorage.removeItem(SUPER_ADMIN);
  };
}

export function tryConnect() {
  return get({
    url: `/api`,
    type: TRY_CONNECT
  });
}

export const getUserProfile = payloads => {
  return get({
    url: `users/${payloads.user_id}`,
    type: GET_USER_PROFILE
  });
};

export const getLoginUser = payloads => {
  return get({
    url: `users/${payloads.user_id}`,
    type: CHECK_USER,
    nextDispatch: AUTH_USER
  });
};

export const setStateofUser = payloads => {
  return post({
    url: `users/set-state`,
    payloads,
    type: GET_USER_PROFILE
  });
};

export const cashierDelete = payloads => {
  return get({ url: `users/cashier-delete?id=${payloads.id}` });
};

export const sendMessageToUser = payloads => {
  return post({
    url: `users/message`,
    payloads
  });
};

export const settingsprofile = profile => {
  return put({
    url: `users/settings/${profile.id}`,
    payloads: profile.userParams,
    type: UPDATE_USER_PROFILE_GOOD,
    typeError: UPDATE_USER_PROFILE_FAIL
  });
};

export const Logindex = payloads => {
  return post({
    url: `user-game-log/index/${payloads.user_id}`,
    payloads,
    type: GAME_LOG_INDEX
  });
};

export const player_index = payloads => {
  return post({
    url: `users/player-index/${payloads.page}`,
    payloads,
    type: PLAYERS_LIST
  });
};


export const get_player_index = payloads => {
  return get({
    url: `users/player-list?page=${payloads.page}&&pageSize=${payloads.pageSize}`,
      type: PLAYERS_LIST
  });
};


export const users_index = payloads => {
  return put({
    url: `users/index/${payloads.page}`,
    payloads,
    type: USER_LIST
  });
};

export const index = payloads => {
  let query = payloads.search ?`limit=${payloads.limit}&search=${payloads.search}`: payloads.user_id ?`user_id=${payloads.user_id}`:`limit=${payloads.limit}&skip=${payloads.skip}`
  return get({
    url: `/users/parent_list?${query}`,
    payloads,
    type: USER_LIST
  });
};

export const deleteUser = payloads => {
  return deleteData({
    url: `users/delete/${payloads.id}/${payloads.create_user_id}/${payloads.amount}`,
    type: USER_DELETE
  });
};

export const getDashboardItemsCount = () => {
  return get({
    url: "users/dashboard-items-count",
    type: DASHBOARD
  });
};

export const createPlayer = formData => {
  return post({
    url: `users/create-player`,
    payloads: formData,
    type: CREATE_PLAYER
  });
};

export const createDeposite = userObj => {
  return post({
    url: `wallets/create-deposite`,
    payloads: userObj,
    type: WALLET_CREATE
  });
};

export const redeeemDeposite = userObj => {
  return post({
    url: `wallets/redeemed-request`,
    payloads: userObj,
    type: WALLET_CREATE
  });
};

export const getbounceback = payloads => {
  return get({
    url: `users/get-bounce-back/${payloads.id}`,
    type: BOUNCEBACK_LIST
  });
};

export const updateUserProfile = profile => {
  return put({
    url: `/users/${profile.id}`,
    payloads: profile.userParams,
    type: UPDATE_USER_PROFILE_GOOD,
    typeError: UPDATE_USER_PROFILE_FAIL
  });
};

export const changeState = payloads => {
  return post({
    url: `users/change-state`,
    payloads
  });
};

export const getBounceBackUserLog = payloads => {
  return get({ url: `users/get-bounce-back-user-log/${payloads.id}` });
};

export const users_getStatistics = payloads => {
  return post({
    url: `users/stat/${payloads.id}`,
    payloads
  });
};

export const getStatistics = payloads => {
  let query = payloads.user_ids? `user_ids=${JSON.stringify(payloads.user_ids)}&limit=${payloads.limit}&skip=${payloads.skip}&startDate=${payloads.startDate}&endDate=${payloads.endDate}`:payloads.startDate && !payloads.param_id? `startDate=${payloads.startDate}&endDate=${payloads.endDate}&limit=${payloads.limit}&skip=${payloads.skip}`:`param_id=${payloads.param_id}&startDate=${payloads.startDate}&endDate=${payloads.endDate}`
  return get({
    url: `users/stats?${query}`,
    payloads
  });
};

export const getJackpotCategories = payload => {
  return get({ url: `users/get-jackpot-category/${payload.id}` });
};

export const getReverseTransactions = payloads => {
  return get({ url: `users/reverse-transactions/${payloads.id}` });
};

export const createChashier = payloads => {
  return post({
    url: `users/create-cashier`,
    payloads,
    type: USER_CREATE
  });
};

export const getTimeZoneList = () => {
  return get({ url: "languages/get-timezone" });
};

export const getPaypoutAndBankList = () => {
  return get({ url: "settings/index" });
};

export const updateCashierSetting = payloads => {
  return post({
    url: `users/update-cashier-setting`,
    payloads,
    type:UPDATE_USER_PROFILE_FAIL
  });
};

export const saveThePayouts = payloads => {
  return put({
    url: `users/payout-update`,
    payloads
  });
};

export const updateThePermissions = payloads => {
  return post({
    url: `settings/permissions`,
    payloads
  });
};

export const getSettings = payloads => {
  return get({ url: `settings/get-settings/${payloads.id}` });
};

export const saveBonus = payloads => {
  let { user } = payloads;
  return post({
    url: `users/save-bonus/${user}`,
    payloads
  });
};

export const getUserFilterList = payloads => {
  return get({
    url: `users/get-userlist/${payloads.key}/${payloads.createdBy}`
  });
};

export const getusers = payloads => {
  return post({
    url: `users/getusers`,
    payloads
  });
};

export const getPanelSettings = () => {
  return get({ url: `users/panel/setting` });
};

export const updatePanelSetting = payloads => {
  return post({
    url: `users/panel/setting/update`,
    payloads
  });
};

export const setPermissions = payloads => {
  return post({
    url: `users/permissions`,
    payloads
  });
};

export const getPermission = payloads => {
  return get({ url: `users/permissions/${payloads.id}` });
};

export const getPlayerCount = payloads => {
  return post({
    url: `users/get-player-count`,
    payloads
  });
};

export const setPrintReciept = payloads => {
  return post({
    url: `print-reciept/save-settings`,
    payloads
  });
};

export const getPrintRecieptSetting = payloads => {
  return get({ url: `print-reciept/get-settings/${payloads.id}` });
};

export const sendMessage = payloads => {
  return post({ url: `users/message`, payloads });
};

export const SetWagerBonus = payloads => {
  return put({ url: `set-wager-bonus`, payloads });
};

export const depositWager = payloads => {
  return post({
    url: `wallets/deposit-wager-bonus`,
    payloads,
    type: WALLET_CREATE
  });
};


export const redeemWager = payloads => {
  return post({
    url: `wallets/redeem-wager-bonus`,
    payloads
  });
};


export const getGamesList = ({url}) => {
  return get({
    url: url,
    type: GAME_LIST
  });
};

export const gameUpdate = ({url, body}) => {
  return put({
    url: url,
    payloads: body
  });
};
