export default {
    items: [  
      { 
        name: 'Accounts management',
        url: '/players',
        icon: 'icon icon-plus',
      },

      {
        name: 'Accounts history',
        url: '/transactions',
        icon: 'icon icon-calendar',
      },
    
      {
        name: '',
        url: '',
        icon: '',
        divider:true,
        title:"RIVER APP"
      },

      {
        name: 'how to download',
        url: '/downloads',
        icon: 'icon icon-book',
      },

      {
        name: 'send link in sms',
        url: '/mobile-link',
        icon: 'icon icon-envelope',
      },
     
      {
        name: '',
        url: '',
        icon: '',
        divider:true
      },
     
      {
        name: 'Logout',
        url: '/logout',
        icon: 'icon icon-power-off',
      },
     
  
  
    ],
  };
  