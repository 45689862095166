import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import config from "./../../actions/config";
import * as actions from "./../../actions";
import "react-toastify/dist/ReactToastify.css";
import { UserRoles } from "../../utils/constants";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_amount: 0,
      first_name: "",
      endpoint: config.baseURL,
      id: "",
      role: "",
      pageheader: false,
    };
    this.getProfile = this.getProfile.bind(this);
  }

  componentWillMount() {
    this.props
      .getLoginUser({ user_id: "profile" })
      .then((res) => {
        if (res["status"] === 200) {
          this.setState({
            first_name: res.data.first_name,
            id: res.data._id,
            role: res.data.role,
          });
          this.setState({ wallet_amount: (res.data.wallet[0] || {}).amount });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  logout = async () => {
    await this.props.signUserOut();
    this.props.history.push("/login");
  };

  getProfile = () => {
    let url = `/setting/${this.state.id}`;
    return url;
  };
  changePassword() {
    window.location = `/users/change-password`;
  }
  render() {
    return (
      <div>
        <div className="navbar navbar-fixed-top">
          <div className="navbar-inner">
            <ToastContainer />
            <div className="container-fluid">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1"
                  aria-expanded="false"
                >
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                <span className="brand">
                  <a href="/">«River»</a>
                </span>
                <ul className="nav navbar-nav">
                  <li className="divider-vertical"></li>
                  <li>
                    <a href="/cashier/report/1">Reports</a>
                  </li>
                  <li className="divider-vertical"></li>
                </ul>
              </div>
              <div
                className="collapse navbar-collapse in"
                id="bs-example-navbar-collapse-1"
              >
                <ul className="nav navbar-nav navbar-right">
                  <li className="dropdown">
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      You logged in as{" "}
                      <span className="label">{this.state.first_name}</span>{" "}
                      <b className="caret"></b>
                    </a>
                    <ul className="dropdown-menu">
                      {this.state.role !== UserRoles.CASHIER ? (
                        <li>
                          <Link to={this.getProfile()}>
                            <i className="fa fa-cog"></i> Settings
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      <li>
                        <a onClick={this.logout}>
                          <i className="fa fa-sign-out"></i> Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>    
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
function mapStateToProps({ auth }) {
  return { auth };
}

export default withRouter(connect(mapStateToProps, actions)(DefaultHeader));
