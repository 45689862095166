import React from 'react'



const Modal = ({ handleClose, show, children,title }) => {
  
    return (
      <div className="modal-animated">
          <div className={show ? "modal display-block" : "modal display-none"}>
          </div>
          <section className="modal-main">
            {title === false ?null:
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true"  onClick={handleClose}>×</button>
            <h3>{title?title:'Android App'} </h3>
          </div>}
            {children}
         
          </section>
      </div>
    );
  };


  export default Modal;