import React, { Component } from "react";
import { signUserIn } from "../../../actions";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import staticMessages from "../../../utils/static_messages";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import Alerts from "../../Notifications/Alerts/index";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      responseStatus: "",
      responseMessage: "",
      //redirectToReferrer: false
    };

    this.v = new SimpleReactValidator({
      messages: {
        required: `:attribute can not be blank !`,
      },
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      return (
        (this.v.allValid() ||
          (this.state.email === "" && this.state.password === "")) &&
        this.setState({ responseStatus: "" })
      );
    });
    // if (this.v.allValid())
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.v.allValid()) {
      let payload = {
        email: this.state.email.trim(),
        password: this.state.password.trim(),
      };
      this.props
        .signUserIn(payload)
        .then(() => {
          this.setState({
            responseMessage: this.props.message,
            responseStatus: this.props.status,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({
        responseMessage: this.v.errorMessages,
        responseStatus: 400,
      });

      this.forceUpdate();
    }
  };

  render() {
    let { responseStatus, responseMessage } = this.state;
   
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form
                      className="login_form"
                      onSubmit={this.handleFormSubmit}
                    >
                      <div className="login_inner">
                        <Alerts status={responseStatus} message={responseMessage} />
                        <InputGroup className="mb-3">
                          <Input
                            type="text"
                            name="email"
                            placeholder="Login"
                            autoComplete="username"
                            onChange={this.handleChange}
                            ref="Login"
                          />
                          {this.v.message(
                            "Login",
                            this.state.email,
                            "required"
                          )}
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            name="password"
                            placeholder="Password"
                            autoComplete="current-password"
                            onChange={this.handleChange}
                            ref="password"
                          />{" "}
                          {this.v.message(
                            "Password",
                            this.state.password,
                            "required"
                          )}
                        </InputGroup>
                        <Col xs="6">
                          <Button
                            type="submit"
                            color="primary"
                            className="px-4"
                          >
                            Login
                          </Button>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
function mapStateToProps({ auth }) {
  if ((auth || {}).error) {
    auth = auth.error;
  }
  return {
    message: auth.message,
    status: auth.status,
  };
}

export default connect(mapStateToProps, { signUserIn })(
  reduxForm({
    form: "login",
  })(Login)
);
