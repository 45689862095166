export default {
  items: [
    {
      name: "Shops management",
      url: "/users",
      icon: "icon icon-user"
    },

    {
      name: "Create Account",
      url: "/users/create",
      icon: "icon icon-plus"
    },

    {
      name: "Transactions history",
      url: "/cashier-history",
      icon: "icon icon-search"
    },

    {
      name: "Statistics",
      url: "/agent/statistics/1",
      icon: "icon icon-signal"
    },

    {
      name: "Jackpots history",
      url: "/jackpot-history",
      icon: "icon icon-gift"
    },
    {
      name: "",
      url: "",
      icon: "",
      divider: true
    },

    {
      name: "Logout",
      url: "/logout",
      icon: "icon icon-power-off"
    }
  ]
};
