import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../views/Widgets/LoadingSpinner";
import shop from "../sidebar/_nav-shop";
import distributor from "../sidebar/_nav_distributor";
import cashier from "../sidebar/_nav-cashier";
import routes from "../routes";
class Permission extends Component {
  constructor() {
    super();
    this.state = {
      permissions: "",
      currentPath: "",
      userRole: "",
      user: "",
    };
  }
  /**
   * Construct a permission array for shop,distributor and cashier from the
   * permission jsons respectively
   * @return permission and pathname
   */
  constructPermisions() {
    let permissions = { shop: [], distributor: [], cashier: [], admin: [] };
    shop.items.forEach((element) => {
      if (element.url !== "") {
        permissions.shop.push(element.url);
        permissions.admin.push(element.url);
      }
    });

    distributor.items.forEach((element) => {
      if (element.url !== "") {
        permissions.distributor.push(element.url);
        permissions.admin.push(element.url);
      }
    });

    cashier.items.forEach((element) => {
      if (element.url !== "") {
        permissions.cashier.push(element.url);
        permissions.admin.push(element.url);
      }
    });

    let apiRoutes = [];
    routes.forEach((element) => {
      let { path, permission } = element;
      apiRoutes.push(path);
      permissions.admin.push(path);
      if (permission === undefined || permission === "undefined") {
        permissions.shop.push(path);
        permissions.distributor.push(path);
        permissions.cashier.push(path);
      } else {
        if (permission.indexOf("shop") !== -1) permissions.shop.push(path);
        if (permission.indexOf("distributor") !== -1)
          permissions.distributor.push(path);
        if (permission.indexOf("cashier") !== -1)
          permissions.cashier.push(path);
      }
    });

    let { pathname } = this.constructPathMaps(
      this.props.location.pathname,
      apiRoutes
    );

    return { permissions, pathname };
  }

  componentDidMount() {
    let { permissions, pathname } = this.constructPermisions();
    this.setState({ permissions: permissions });
    this.setState({ currentPath: pathname });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ userRole: nextProps.user_role.toLowerCase() });
  }

  componentDidUpdate() {
    if (this.props.user_role) {
      this.checkPermission();
    }
  }
  /**
   * check the permission and if not permitted redirect to 404
   */
  checkPermission() {
    let { permissions, currentPath, userRole } = this.state;
    if (userRole !== "") {
      if (permissions[userRole].indexOf(currentPath) === -1) {
        return this.props.history.push("/404");
      }
    }
    return;
  }
  /**
   * @param {*} pathname
   * @param {*} pathmap
   * @return Return the pathname and params of a parameterized path against an url
   * of same struct
   */
  constructPathMaps(pathname, pathmap) {
    let found = false;
    let params = {};
    for (let i = 0; i < pathmap.length; i++) {
      if (!found) {
        let eachPath = pathmap[i];
        if (eachPath.indexOf(":" > -1)) {
          let path = [];
          let newPathname = [];
          path = eachPath.split("/");
          path.forEach((iPath, index) => {
            if (eachPath.indexOf(":") > -1) {
              if (iPath.indexOf(":") > -1) {
                newPathname = pathname.split("/");
                if (newPathname.length === path.length) {
                  if (newPathname[index - 1] === path[index - 1]) {
                    params[path[index].replace(":", "")] = newPathname[index];
                    newPathname.splice(index, 1, iPath);
                    pathname = newPathname.join("/");
                    found = true;
                  }
                }
              }
            }
          });
        } else {
          continue;
        }
      } else {
        break;
      }
    }

    return { pathname: pathname, params: params };
  }

  render() {
    return (
      <div className="inlined">
        {this.props.user_role ? (
          this.props.children
        ) : (
          <LoadingSpinner></LoadingSpinner>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Permission);
