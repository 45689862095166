export default {
  items: [
    {
      name: 'Accounts management',
      url: '/players',
      icon: 'icon icon-plus',
    },
    {
      name: 'Accounts history',
      url: '/transactions',
      icon: 'icon icon-calendar',
    },
    {
      name: 'Transactions history',
      url: '/cashier-history',
      icon: 'icon icon-search',
    },
    {
      name: 'Jackpots History',
      url: '/jackpot-history',
      icon: 'icon icon-gift',
    },
    {
      name: 'Cashiers management',
      url: '/cashier-index',
      icon: 'icon icon-user',
    },
    {
      name: '',
      url: '',
      icon: '',
      divider: true
    },
    {
      name: "how to download",
      url: "/downloads",
      icon: "icon icon-book"
    },

    {
      name: "send link in sms",
      url: "/mobile-link",
      icon: "icon icon-envelope"
    },
    {
      name: '',
      url: '',
      icon: '',
      divider: true
    },
    {
      name: 'Logout',
      url: '/logout',
      icon: 'icon icon-power-off',
    }
  ]
};
