import {SUPER_ADMIN} from '../utils/constants';
let isSuperAdmin = Number(localStorage.getItem(SUPER_ADMIN));

export default {
  items: [
    {
      name: 'User Management',
      url: '/users',
      icon: 'icon icon-user',
    },

    {
      name: 'Create Account',
      url: '/users/create',
      icon: 'icon icon-plus',
    },

    {
      name: 'Transactions history',
      url: '/cashier-history',
      icon: 'icon icon-search',
    },
    {
      name: 'Players Management',
      url: '/players',
      icon: 'fa fa-users',
    },

    isSuperAdmin && {
      name: 'Jackpot Management',
      url: '/jackpot-category',
      icon: 'fa fa-gift',
    },

    {
      name: 'Statistics',
      url: '/agent/statistics/1',
      icon: 'fa fa-signal',
    },

    /****
     * It should only work for root admin only
     */
    isSuperAdmin ? {
      name: 'Games',
      url: '/games',
      icon: 'fa fa-gamepad',
    } : [],
    {
      name: '',
      url: '',
      icon: '',
      divider: true
    },

    {
      name: 'Logout',
      url: '/logout',
      icon: 'icon icon-power-off',
    }

  ],
};
