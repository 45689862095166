export const JWT_TOKEN = "auth_jwt_token";
export const ROLE = "role";
export const SUPER_ADMIN = "is_superadmin";
export const androidLinkQR =
  "https://play.google.com/store/apps/details?id=com.club7.playriver&hl=en";
export const iosLinkQR = "https://apps.apple.com/in/app/rsfun/id1495582389"
export const androidLink = "https://www.bet777.games/";

export const Status = {
  Active: "Active",
  Inactive: "Inactive",
  Pending: "Pending",
  Banned: "Banned",
};

export const Classes = {
  SUCCESS: "success",
  SECONDARY: "secondary",
  WARNING: "warning",
  DANGER: "danger",
  PRIMARY: "primary",
};

export const UserRoles = {
  SHOP: "SHOP",
  DISTRIBUTOR: "DISTRIBUTOR",
  ADMIN: "ADMIN",
  CASHIER: "CASHIER",
  S_SHOP: "shop",
  S_DISTRIBUTOR: "distributor",
  S_ADMIN: "admin",
  S_CASHIER: "cashier",
};

export const ShopType = {
  SweepStakes: "Sweepstakes",
  Classic: "Classic",
};

export const authKeys = {
  authorization: "Authorization",
  apiHeaderTokenPrefix: "Bearer",
};

export const localStorageKeys = {
  accessToken: "auth_jwt_token",
};

export const PacificTimeZone = "5c6e7a5931f58e3eb12cc2d8";
export const EnglishLanguage = "5c3dbc21cfc4a279e0998206";

export const Percentages = [
  { id: "0 %", value: 0 },
  { id: "10 %", value: 10 },
  { id: "20 %", value: 20 },
  { id: "30 %", value: 30 },
  { id: "40 %", value: 40 },
  { id: "50 %", value: 50 },
  { id: "60 %", value: 60 },
  { id: "70 %", value: 70 },
  { id: "80 %", value: 80 },
  { id: "90 %", value: 90 },
  { id: "100 %", value: 100 },
];

export const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const offSetObject = [
  { _id: "-12:00", value: "-11" },
  { _id: "-11:00", value: "-10" },
  { _id: "-10:00", value: "-9" },
  { _id: "-9:00", value: "-8" },
  { _id: "-8:00", value: "-7" },
  { _id: "-7:00", value: "-6" },
  { _id: "-6:00", value: "-5" },
  { _id: "-5:00", value: "-4" },
  { _id: "-4:00", value: "-3" },
  { _id: "-3:30", value: "-3" },
  { _id: "-2:00", value: "-1" },
  { _id: "-1:00", value: "0" },
  { _id: "-12:00", value: "-12" },
  { _id: "+1:00", value: "+1" },
  { _id: "+2:00", value: "+2" },
  { _id: "+3:00", value: "+3" },
  { _id: "+3:30", value: "+3.5" },
  { _id: "+4:00", value: "+4" },
  { _id: "+4:30", value: "+4.5" },
  { _id: "+5:00", value: "+5" },
  { _id: "+5:30", value: "+5.5" },
  { _id: "+5:45", value: "+5.75" },
  { _id: "+6:00", value: "+6" },
  { _id: "+7:00", value: "+7" },
  { _id: "+8:00", value: "+8" },
  { _id: "+9:00", value: "+9" },
  { _id: "+9:30", value: "+9.5" },
  { _id: "+10:00", value: "+10" },
  { _id: "+11:00", value: "+11" },
  { _id: "+12:00", value: "+12" },
];

export const API_EndPoints = {
  GET_GAMES: "games",
};

export const Okay = "OK";
export const Out_All = "Out All";
export const DSA = "Don't Show Again";
export const Redeem_Button = "Redeem";
export const defaultTimezone = "America/Metlakatla";

export const buttonText = {
  Okay: "OK",
  Out_All: "Out All",
  DSA: "Don't Show Again",
  Redeem_Button: "Redeem",
  Cancel: "Cancel",
  SaveChanges: "Save Changes",
  Delete: "Delete",
  Edit: "Edit",
  EnableDisable: "Enable/Disable",
  Enable: "Enable",
  Disable: "Disable",
  Apply: "Apply",
  Update: "Update",
  Send: "SEND",
  Submit: "Submit",
  Close: "Close",
  Reverse: "Reverse",
  Search: "Search",
};

export const constantText = (value, title) => {
  return {
    Close: "Close",
    AccountNo: "Account #",
    UserName: "Username / Notes",
    CreatedAt: "Created",
    Balance: "Balance",
    BalanceWager: "Balance / Playable",
    DepositeRedeem: "Deposit / Redeem",
    State: "State",
    Deposit: "Deposit",
    Redeem: "Redeem",
    Lock: "Lock",
    Reverse: "Reverse",
    Logs: "Logs",
    Shop: "Shop",
    Login: "Login ",
    MasterFields: "Master Fields",
    Password: "Password",
    RepeatPassword: "Repeat Password",
    Denomination: "Denomination",
    RegionalSettings: "Regional settings",
    Language: "Language",
    TimeZone: "Time Zone",
    Country: "Country",
    City: "City",
    Name: "Name",
    Email: "E-mail",
    TelNumber: "Tel. number",
    ContactDetails: "Contact Details",
    Mode: "Mode ",
    PleaseSelect: `Please Select ${value}`,
    AllowedIp: "Allowed IP",
    commaSeparated: "comma separated",
    ReceiptPrint: "Receipt print option",
    Disabled: "Disabled",
    Enabled: "Enabled",
    ReceiptTemplate: "Receipt template",
    Default: "Default",
    ReceiptFont: "Receipt font",
    AutoPrint: "Auto print",
    Brand: "Brand",
    LocationName: "Location name",
    LocationAddress: "Location address",
    CashierName: "Cashier's name",
    ShowQR: "Show QR-code",
    WWW: "WWW",
    Type: "Type",
    LastLogin: "Last Login",
    Manage: "Manage",
    Delete: "Delete",
    CreateTitle: `Create ${title}`,
    Bonus: "Bonus",
    EnableBonus: "Enable Bonus",
    GameType: "Game Type ",
    Finance: "Finance",
    AccountType: "Account Type ",
    Active: "Active",
    Start: "Start",
    Hours: "Hours",
    Percent: "%",
    Day: "Day",
    PhoneNumber: "Phone number",
    SelectPlatform: "Select Platform",
    Amount: "Amount",
    Username: "Username",
    ChangePassword: "Change Password",
    Jackpots: "Jackpots",
    JackpotAccount: "Jackpot account",
    Deposits: "Deposits",
    Redeems: "Redeems",
    Profit: "Profit",
    PayOut: "PayOut %",
    Jackpotstatus: "Jackpots status",
    Options: "Options",
    CashBack: "CashBack",
    Status: "Status",
    BounceBack: "BounceBack",
    BouncebackLimit: "Bounceback Limit",
  };
};

export const fonts = ["Graduate", "Electrolize", "Arial", "Times New Roman"];

export const transactionActions = {
  Deposit: "Deposit",
  CreateAccount: "Create account",
  Reverse: "Reverse",
  Redeem: "Redeem",
};

export const formMenu = {
  General: "General",
  Jackpots: "Jackpots",
  Bonuses: "Bonuses",
  PrintReceipts: "Print receipts",
  Payout: "Payout",
  Permission: "Permission",
  HappyHours: " Happy Hours",
  WagerBonus: "Wager Bonus",
};

export const PermissionsForm = {
  BONUSES: "BONUSES ",
  JACKPOTS: "JACKPOTS ",
  PAYOUT: "PAYOUT ",
  CREATESHOP: "CREATE SHOP ",
  HAPPYHOURS: "HAPPY HOURS ",
  PRINTRECIEPT: "PRINT RECIEPT ",
  CREATEDISTRIBUTOR: "CREATE DISTRIBUTOR"
};

export const JackpotTable = [
  "Name",
  "Type",
  "Charge",
  "Min Value",
  "Max Value",
  "Starting Value",
  "Min Bet",
  "Members number",
  "Current Amount",
];
