import React from "react";
import Loadable from "react-loadable";

import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import("./views/Users/Users"),
  loading: Loading,
});

const Statistics = Loadable({
  loader: () => import("./views/Users/Statistics"),
  loading: Loading,
});

const Permissions = Loadable({
  loader: () => import("./views/Users/UserSetting"),
  loading: Loading,
});

const CashierIndex = Loadable({
  loader: () => import("./views/Users/CashierIndex"),
  loading: Loading,
});

const Setting = Loadable({
  loader: () => import("./views/Users/Setting"),
  loading: Loading,
});

const CashierSetting = Loadable({
  loader: () => import("./views/Users/CashierSetting"),
  loading: Loading,
});

const Download = Loadable({
  loader: () => import("./views/Users/Download"),
  loading: Loading,
});

const Message = Loadable({
  loader: () => import("./views/Users/Message"),
  loading: Loading,
});

const CreateUser = Loadable({
  loader: () => import("./views/Users/CreateUser"),
  loading: Loading,
});

const UpdateUser = Loadable({
  loader: () => import("./views/Users/UpdateUser"),
  loading: Loading,
});

const Players = Loadable({
  loader: () => import("./views/Users/Players"),
  loading: Loading,
});

const Transactions = Loadable({
  loader: () => import("./views/Transactions/Transactions"),
  loading: Loading,
});

const CashierTransaction = Loadable({
  loader: () => import("./views/Transactions/CashierTransaction"),
  loading: Loading,
});

const UserGameLogs = Loadable({
  loader: () => import("./views/UserGameLog/UserGameLogs"),
  loading: Loading,
});

const JackpotCategories = Loadable({
  loader: () => import("./views/JackpotCategory/JackpotCatgories"),
  loading: Loading,
});

const UpdateJackpotCategory = Loadable({
  loader: () => import("./views/JackpotCategory/Update"),
  loading: Loading,
});

const JackpotHistory = Loadable({
  loader: () => import("./views/JackpotHistory/JackpotHistories"),
  loading: Loading,
});

const PanelSettings = Loadable({
  loader: () => import("./views/PanelSetting/PanelSettings"),
  loading: Loading,
});

const Charts = Loadable({
  loader: () => import("./views/Language/Charts"),
  loading: Loading,
});

const CashierReport = Loadable({
  loader: () => import("./views/Reports/CashierReports"),
  loading: Loading,
});

// const PrintPreview = Loadable({
//   loader: () => import('./views/Reports/PrintPreview'),
//   loading: Loading,
// });

const Games = Loadable({
  loader: () => import("./views/Games/Games"),
  loading: Loading,
});

const PrintPreview = Loadable({
  loader: () => import("./views/Reports/PrintPreview"),
  loading: Loading,
});

const routes = [
  { path: "/", exact: true, name: "Home", component: DefaultLayout },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    permission: ["distributor"],
  },
  {
    path: "/users/update/:id",
    exact: true,
    name: "Update",
    component: UpdateUser,
    permission: ["distributor"],
  },
  {
    path: "/setting/:id",
    exact: true,
    name: "My settings",
    component: Setting,
    permission: ["distributor", "cashier", "shop"],
  },
  {
    path: "/users",
    exact: true,
    name: "Users",
    component: Users,
    permission: ["distributor"],
  },
  {
    path: "/cashier-index",
    exact: true,
    name: "Cashiers management",
    component: CashierIndex,
    permission: ["shop"],
  },
  {
    path: "/cashier-setting/:id",
    exact: true,
    name: "Cashier management",
    component: CashierSetting,
    permission: ["shop", "cashier", "distributor"],
  },
  {
    path: "/downloads",
    exact: true,
    name: "How to download",
    component: Download,
    permission: ["distributor"],
  },
  {
    path: "/mobile-link",
    exact: true,
    name: "Send link in sms",
    component: Message,
    permission: ["distributor"],
  },

  {
    path: "/permissions/:id",
    exact: true,
    name: "Permissions",
    component: Permissions,
  },
  {
    path: "/users/create",
    exact: true,
    name: "Create User",
    component: CreateUser,
    permission: ["distributor"],
  },
  /***
   *
   * Account Management OR Players Management
   * * */
  {
    path: "/players",
    exact: true,
    name: "Accounts Management",
    component: Players,
    permission: ["cashier", "shop", "admin"],
  },
  /****
   *
   * Transactions
   *
   */
  {
    path: "/transactions",
    exact: true,
    name: "Accounts history",
    component: Transactions,
    permission: ["cashier", "shop", "admin"],
  },
  {
    path: "/cashier-history",
    exact: true,
    name: "Transactions history",
    component: CashierTransaction,
    permission: ["cashier", "shop"],
  },
  {
    path: "/cashier-history/:id",
    exact: true,
    name: "Transactions history",
    component: CashierTransaction,
    permission: ["cashier", "shop", "distributor"],
  },
  {
    path: "/transactions/cashier-history/:id",
    exact: true,
    name: "Transactions history",
    component: CashierTransaction,
    permission: ["distributor", "shop", "admin"],
  },
  {
    path: "/transactions/:id",
    exact: true,
    name: "Transactions",
    component: Transactions,
    permission: ["cashier", "shop"],
  },
  {
    path: "/user-games-log/:user_id",
    exact: true,
    name: "Spins log for account",
    component: UserGameLogs,
  },
  {
    path: "/jackpot-category",
    exact: true,
    name: "JackpotCategories",
    component: JackpotCategories,
  },
  {
    path: "/jackpot-category/update/:id",
    exact: true,
    name: "JackpotCategories Update",
    component: UpdateJackpotCategory,
  },
  {
    path: "/agent/statistics/:id",
    exact: true,
    name: "Statistics",
    component: Statistics,
  },
  /****
   *
   * Jackpot-History
   *
   */
  {
    path: "/jackpot-history",
    exact: true,
    name: "Jackpots History",
    component: JackpotHistory,
    permission: ["cashier", "shop"],
  },
  /**
   *
   * PanelSettings
   *
   * * */
  {
    path: "/game-settings",
    exact: true,
    name: "PanelSetting",
    component: PanelSettings,
  },
  { path: "/charts", exact: true, name: "Charts", component: Charts },
  {
    path: "/cashier/report/:id",
    exact: true,
    name: "Reports",
    component: CashierReport,
  },
  {
    path: "/games",
    exact: true,
    name: "Games",
    component: Games,
    permission: ["admin"],
  },
  {
    path: "/pdf-priview/:id",
    exact: true,
    name: "Pdf Preview",
    component: PrintPreview,
  },
];
export default routes;
