const staticMessages = {
  BlankLogin: "Login cannot be blank!",
  BlankPassword: "Password cannot be blank!",
  IncorrectIp: "Incorrect IP address (eg. 127.0.0.1)",
  BlankType: "Game Type cannot be blank!",
  BlankRole: "Account Type cannot be blank!",
  TimeZone: "Time Zone must be selected",
  BlankConfirm: "Confirm Password cannot be blank!",
  BlankMode: "Mode cannot be blank!",
  ConfirmPassowrd: "The confirm password is equal to password.",
  UnlockConfirm: "The account will be Unlocked. Are you sure to do this ?",
  DisconnectConfirm:
    "The account will be disconnected and locked, Are you sure to do this ?",
  DeleteConfirm: "The account will be Deleted, Are you sure to do this?",
  SubmitConfirm: "Confirm to Submit",
  PasswordRepeat: "Password must be repeated exactly.",
  InvalidToken: "Invalid Token",
  UserNotFound: "User Not Found",
  ServerError: "Server Error,try later",
  IncorrectPassword: "Incorrect Password",
  TryAgainPassword: "Incorrect Password. Please try it again.",
  UserUpdateSuccess: "User is updated successfully",
  UserCreateSuccess: "User is created successfully",
  SomeWentWrong: "Something Went Wrong",
  BuildUpdateSuccess: "Build Update Successfully",
  WalletDelete: "Success! Wallet is Deleted Successfully",
  OppsSomeWentWrong: "Oops! Something Went Wrong",
  AmountUpdateSuccess: "Success! Amount is Updated Successfully",
  MoneyAddSuccess: "Money is Added To Wallet Succesfully",
  UpdateSuccess: "Updated Successfully",
  PasswordNotMatch: "Password does not match",
  PasswordMustMatch: "Password must match",
  NoOptions: "No Options",
  PasswordLength: "Password must be at least 6 characters long.",
  PasswordMax:"Password can not exceed 64 characters",
  UsernameMax:"Login can not exceed 50 characters",
  MoreCharacters: "Please enter 2 or more characters",
  ValidEmail:
    "The  Email must be a valid email address. It can not contain '\xa0\xa0' , \\",
  UsernameBS: `Login cannot contain \\`,
  oldPassword: "Old Password is required, to change the password",
  ifPassword: "Password is required, to change the password",

  spacedPswd: "Password can not contain spaces",
  BalanceError: "Balance must be upto 2 decimal",
};

export default staticMessages;
