import {
  AUTH_USER,
  UNAUTH_USER,
  AUTH_ERROR,
  TRY_CONNECT,
  USER_LIST,
  PLAYERS_LIST,
  USER_LOGGEDIN
} from '../actions/types';

let INITIAL_STATE = {
  authenticated: false,
  user: null,
  isLoggedIn:true
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, error: '', authenticated: true, user: action.payload}
    case UNAUTH_USER:
      return { ...state, authenticated: false, isLoggedIn:false, user:{}, error:"" }
    case AUTH_ERROR:
      return { ...state, error: action.payload }
    case TRY_CONNECT:
      return { ...state, status: action.payload }
    case USER_LIST:
      return { ...state, status: action.payload }
    case PLAYERS_LIST:
      return { ...state, status: action.payload }
    case USER_LOGGEDIN:
      return {...state, isLoggedIn:false}
    default:
      return state
  }
}