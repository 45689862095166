import React, { Component } from "react";
import { Alert, Col } from "reactstrap";
import { createUser } from "../../Users/includes/constantFunction";

class Alerts extends Component {
  onDismiss = () => {
    this.props.dismiss();
  };

  setHtml = (message) => {
    let newArr = Object.values(message);
    let newMess = [];
    newArr.map((item) => {
      if (typeof item === "string") newMess.push(item);
    });
    return newMess;
  };

  render() {
    const { status, message, create, dismiss , transactions} = this.props;
    let newMessage =
      message && !transactions && typeof message === "object" ? this.setHtml(message) : message;
    if (create && status === 400) {
      newMessage =
        message.split(" ").pop() === "taken" ? message : createUser(message);
    }
    return (
      <Col lg={12}>
        <Alert
          fade={false}
          toggle={dismiss ? this.onDismiss : false}
          isOpen={status === 200 || status === 400 ? true : false}
          color={status === 200 ? "success" : "danger"}
        >
          {newMessage && !transactions  && typeof newMessage === "object" ? newMessage.map((items, key)=>{
            return<div key={key}>{items}</div>
          }):newMessage}
        </Alert>
      </Col>
    );
  }
}

export default Alerts;
