import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, Alert, Button } from "reactstrap";
import * as actions from "./../../actions";
import { connect } from "react-redux";
import Clock from "react-live-clock";
import socketIOClient from "socket.io-client";
import { AppBreadcrumb } from "@coreui/react";
import navigation from "../../sidebar/_nav";
import distributor_navigation from "../../sidebar/_nav_distributor";
import shop_navigation from "../../sidebar/_nav-shop";
import cashier_navigation from "../../sidebar/_nav-cashier";
import Permission from "../../components/Permission";
// routes config
import routes from "../../routes";
import DefaultHeader from "./DefaultHeader";
import config from "./../../actions/config";
import LoadingSpinner from "../../views/Widgets/LoadingSpinner";
import { UserRoles, ShopType } from "../../utils/constants";
import _helper from "../../_helper";
import Modal from "../../views/Widgets/Modal";
import _ from "lodash";

const getSideBar = (role, jackpot, superAdmin) => {
  if (role === UserRoles.ADMIN) {
    return navigation;
  } else if (role === UserRoles.DISTRIBUTOR) {
    if (jackpot === false) {
      _.remove(distributor_navigation.items, (e) => {
        return e.name === "Jackpots history";
      });
    }
    return distributor_navigation;
  } else if (role === UserRoles.CASHIER) {
    return cashier_navigation;
  } else if (role === UserRoles.SHOP) {
    return shop_navigation;
  } else {
    return "none";
  }
};

const getWalletDescription = (value) => {
  return (
    <div className="alert alert-warning">
      <p className="colOr-layout">
        Your balance:{" "}
        <b className="wallet-amount">
          {_helper.twoDecimalConversion(value)} USD
        </b>
      </p>
    </div>
  );
};

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet_amount: 0,
      user_id: "",
      endpoint: config.baseURL,
      socket: null,
      role: "",
      created_user_id: "",
      login_user: {},
      can_set_jackpot: "",
    };
    this.supportOptions = this.supportOptions.bind(this);
  }

  componentWillMount() {
    if (config.env !== "local") {
      const socket = socketIOClient(config.baseURL, {
        transports: ["websocket"],
        secure: true,
      });
      this.setState({ socket: socket });
    } else {
      const socket = socketIOClient(config.baseURL);
      this.setState({ socket: socket });
    }
  }

  componentDidMount() {
    this.props
      .getLoginUser({ user_id: "profile" })
      .then((res) => {
        if (res["status"] === 200) {
          this.props.getPermission({ id: res.data._id }).then((res) => {
            res.data &&
              this.setState({ can_set_jackpot: res.data.can_set_jackpot });
          });
          if (res["data"].role === UserRoles.CASHIER) {
            this.setState({ user_id: res["data"].create_user_id });
            this.props
              .getLoginUser({ user_id: res["data"].create_user_id })
              .then((resp) => {
                this.setState({ role: res["data"].role });
                this.setState({
                  wallet_amount: resp["data"].wallet[0].amount,
                });
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.setState({
            user_id: res["data"]._id,
            role: res["data"].role,
            login_user: res["data"],
            created_user_id: res["data"].create_user_id,
            wallet_amount:
              res["data"].wallet.length > 0 ? res["data"].wallet[0].amount : 0,
          });

          /**
           * Conditional Rewrite
           */
          if (res["data"].role) {
            if (
              res["data"].role.toLowerCase() === UserRoles.S_DISTRIBUTOR &&
              this.props.location.pathname === "/"
            ) {
              return this.props.history.push("/users");
            }

            if (
              res["data"].role.toLowerCase() === UserRoles.S_SHOP &&
              this.props.location.pathname === "/"
            ) {
              return this.props.history.push("/players");
            }
            if (
              res["data"].role.toLowerCase() === UserRoles.S_CASHIER &&
              this.props.location.pathname === "/"
            ) {
              return this.props.history.push("/players");
            }
            if (
              res["data"].role.toLowerCase() === UserRoles.S_ADMIN &&
              this.props.location.pathname === "/"
            ) {
              return this.props.history.push("/dashboard");
            }
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  componentDidUpdate(nextProps) {
    let mode =
      this.props.user &&
      this.props.user.profile &&
      this.props.user.profile.length &&
      this.props.user.profile[0].mode === ShopType.SweepStakes;
    if (this.state.role) {
      if (
        this.state.role.toLowerCase() === UserRoles.S_DISTRIBUTOR &&
        this.props.location.pathname === "/"
      ) {
        return this.props.history.push("/users");
      }

      if (
        this.state.role.toLowerCase() === UserRoles.S_SHOP &&
        this.props.location.pathname === "/"
      ) {
        return this.props.history.push("/players");
      }
      if (
        this.state.role.toLowerCase() === UserRoles.S_CASHIER &&
        this.props.location.pathname === "/"
      ) {
        return this.props.history.push("/players");
      }
      if (
        this.state.role.toLowerCase() === UserRoles.S_ADMIN &&
        this.props.location.pathname === "/"
      ) {
        return this.props.history.push("/dashboard");
      }
    }

    let api = "update_distributor_or_shop_wallet_" + this.state.user_id;
    this.state.socket.on(api, (message) => {
      this.setState({ wallet_amount: message });
      this.state.socket.emit("connectwithsocket", {
        user_id: this.state.user_id,
      });
    });

    // this.state.socket.on(`USER_STATUS_${this.state.user_id}`, (data) => {
    //   let { status, user_id } = data;
    //   let element = document.getElementById(user_id);
    //   let reverseElement = document.getElementById(`rb${user_id}`);
    //   if (
    //     (element && element !== undefined) ||
    //     (reverseElement && reverseElement !== undefined)
    //   ) {
    //     if (status === 0) {
    //       element.classList.remove("badge-success");
    //       element.classList.add("badge");
    //       element.classList.add("label-important");
    //       element.innerText = "offline";
    //       mode &&
    //         reverseElement.classList.remove(
    //           "btn-default",
    //           "grey-bg",
    //           "disabled"
    //         );
    //       if (mode) reverseElement.disabled = false;
    //     } else {
    //       element.classList.remove("label-important");
    //       element.classList.add("badge");
    //       element.classList.add("badge-success");
    //       element.innerText = "online";
    //       mode && reverseElement.classList.remove("btn-success");
    //       if (mode) reverseElement.disablComposedComponented = true;
    //       mode &&
    //         reverseElement.classList.add("btn-default", "grey-bg", "disabled");
    //     }
    //   }
    // });

    // let socketVar = [
    //   "WALLET",
    //   "update_jackpot_values_for_shop_",
    //   "DAILY_WHEEL_",
    //   "CASHBACK_WHEEL_",
    // ];

    // socketVar.forEach((element) => {
    //   this.state.socket.on(`${element}${this.state.user_id}`, (data) => {
    //     let newData =
    //       element === "update_jackpot_values_for_shop_"
    //         ? data.jackpotDetails
    //         : data;
    //     if (newData) {
    //       let {
    //         user_id,
    //         wallet_amount,
    //         redeemed_amount,
    //         credit,
    //         total_win,
    //         userId,
    //         wager_amount,
    //       } = newData;
    //       let _id = user_id ? user_id : userId;
    //       let wallet = document.getElementById(`wxa_${_id}`);
    //       let redeemed = document.getElementById(`rda_${_id}`);
    //       let wager = document.getElementById(`wga_${_id}`);
    //       if (wager) {
    //         let updated_wager =
    //           !wager_amount || wager_amount === 0 ? "0.00" : wager_amount;
    //         let updated_w =
    //           credit === 0 || wallet_amount === 0
    //             ? "0.00"
    //             : credit
    //             ? credit
    //             : wallet_amount;

    //         wager.innerText = (
    //           Math.round(
    //             (parseFloat(updated_w) + parseFloat(updated_wager)) * 100
    //           ) / 100
    //         ).toFixed(2);
    //       }
    //       if (wallet) {
    //         let updated_w =
    //           credit === 0 || wallet_amount === 0
    //             ? "0.00"
    //             : credit
    //             ? credit
    //             : wallet_amount;
    //         wallet.innerText = (
    //           Math.round(parseFloat(updated_w) * 100) / 100
    //         ).toFixed(2);
    //       }
    //       if (redeemed) {
    //         let updated_R =
    //           total_win === 0 || redeemed_amount === 0
    //             ? "0.00"
    //             : total_win
    //             ? total_win
    //             : redeemed_amount;
    //         redeemed.innerText = (
    //           Math.round(parseFloat(updated_R) * 100) / 100
    //         ).toFixed(2);
    //       }
    //     }
    //   });
    // });

    this.state.socket.on(`UserAccountClose${this.state.user_id}`, (data) => {
      this.props.logUserOut();
    });
  }

  supportOptions() {
    if (this.state.role !== UserRoles.ADMIN) {
      return (
        <button
          type="button"
          id="emergency-button"
          className="btn btn-success btn-block"
        >
          Help
        </button>
      );
    }
  }

  render() {
    const { wallet_amount, role, login_user, can_set_jackpot } = this.state;

    let timeZone =
      login_user &&
      login_user.profile &&
      login_user.profile.length &&
      login_user.profile[0].time_zone
        ? login_user.profile[0].time_zone.timeZone
        : null;
    let route =
      role === UserRoles.SHOP
        ? "/players"
        : role === UserRoles.DISTRIBUTOR
        ? "/users"
        : role === UserRoles.CASHIER
        ? "/players"
        : "/dashboard";
    return (
      <div className="app notforprint">
        <DefaultHeader loginUser={this.props.user} />
        <div className="app-body container-fluid">
          <div className="row">
            <div className="col-lg-2 col-sm-3 fixed_layout">
              {getWalletDescription(wallet_amount)}
              <div className="navBar-header"></div>
              <div className="well well-small">
                <ul className="nav nav-list">
                  {getSideBar(role) !== "none" ? (
                    getSideBar(
                      role,
                      can_set_jackpot,
                      login_user.is_superadmin
                    ).items.map((navigation, idx) => {
                      if (navigation) {
                        if (navigation.divider) {
                          return (
                            <div key={idx}>
                              <li className="divider"></li>
                              {navigation.title ? (
                                <li
                                  className="nav-header"
                                  key={navigation.title}
                                >
                                  {navigation.title}
                                </li>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <li key={idx}>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={(event) =>
                                  this.props.location.pathname ===
                                  navigation.url
                                    ? window.location.reload()
                                    : this.props.history.push(navigation.url)
                                }
                              >
                                {" "}
                                <i className={navigation.icon}></i>{" "}
                                {navigation.name}
                              </a>
                            </li>
                          );
                        }
                      }
                    })
                  ) : (
                    <LoadingSpinner />
                  )}
                </ul>
              </div>
              {timeZone ? (
                <blockquote>
                  <p className="muted">
                    <Clock
                      timezone={timeZone}
                      format={"YYYY/MM/DD HH:mm:ss"}
                      ticking={true}
                    />
                    <br />
                    {`GMT ${_helper.getOffset(timeZone)}`}
                  </p>
                </blockquote>
              ) : null}
            </div>
            <div className="col-sm-9 col-lg-10 fixed_layout padd_inG-left">
              <main className="main">
                <AppBreadcrumb className="container-fluid" appRoutes={routes} />
                {this.props.auth && this.props.isLoggedIn ? (
                  <Container fluid>
                    <Switch>
                      <Permission {...this.props} user_role={role}>
                        {routes.map((route, idx) => {
                          return route.component ? (
                            <Route
                              key={idx}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={(props) => (
                                <route.component
                                  {...props}
                                  loginUser={login_user}
                                  user_role={role}
                                />
                              )}
                            />
                          ) : null;
                        })}
                      </Permission>
                      <Redirect from="/" to={route} />
                    </Switch>
                  </Container>
                ) : (
                  <Modal
                    title={false}
                    show={true}
                    children={
                      <div
                        style={{ padding: "35px", textAlign: "center" }}
                        className="modal-body"
                      >
                        <Alert color="danger" isOpen={true} fade={false}>
                          Session Expired, Please Login Again ...
                        </Alert>
                        <Button
                          onClick={() => this.props.logUserOut()}
                          size="sm"
                          color="danger"
                        >
                          Login
                        </Button>
                      </div>
                    }
                  ></Modal>
                )}
              </main>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <hr></hr>
          <footer className="pull-right">
            <small className="muted">© River {new Date().getFullYear()}</small>
          </footer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    auth: state.auth.authenticated,
    isLoggedIn: state.auth.isLoggedIn,
  };
};

export default connect(mapStateToProps, actions)(DefaultLayout);
