export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const TRY_CONNECT = 'TRY_CONNECT';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_PROFILE_GOOD = 'UPDATE_USER_PROFILE_GOOD';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';
export const USER_LIST = 'USER_LIST';
export const USER_DELETE = 'USER_DELETE';
export const USER_CREATE = 'USER_CREATE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const DASHBOARD = 'DASHBOARD';
export const PLAYERS_LIST = 'PLAYERS_LIST';
export const CREATE_PLAYER = 'CREATE_PLAYER';
export const REEDEMED_REQUEST = 'REEDEMED_REQUEST';
export const CHECK_USER = 'CHECK_USER';
export const TIMEZONE_LIST = 'TIMEZONE_LIST';
export const SETTING_LIST = 'SETTING_LIST';
export const GAME_LIST = 'GAME_LIST';
export const USER_LOGGEDIN = 'USER_LOGGEDIN'

//Wallet Dispatcher
export const WALLET_CREATE = 'WALLET_CREATE';
export const WALLET_INDEX = 'WALLET_INDEX';
export const WALLET_UPDATE = 'WALLET_UPDATE';
export const WALLET_VIEW = 'WALLET_VIEW';
export const WALLET_DELETE = 'WALLET_DELETE';
export const WALLET_USER_LIST = 'WALLET_USER_LIST';
export const TRANSACTION_LIST = 'TRANSACTION_LIST';


//Games Dispatcher
export const GAME_UPDATE = 'GAME_UPDATE';
export const GAME_INDEX = 'GAME_INDEX';
export const GAME_LOG_INDEX = 'GAME_LOG_INDEX';


//JACKPOT_CATEGORIES Dispatcher
export const JACKPOT_CATEGORIES = 'JACKPOT_CATEGORIES';
export const JACKPOT_CATEGORIES_VIEW = 'JACKPOT_CATEGORIES_VIEW';
export const JACKPOT_CATEGORIES_UPDATE = 'JACKPOT_CATEGORIES_UPDATE';
export const LANGUAGE_INDEX = 'LANGUAGE_INDEX';
export const BOUNCEBACK_LIST = 'BOUNCEBACK_LIST';


