import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "../../actions";
import { Redirect } from "react-router-dom";
import { redirectLocation } from "../../views/Users/includes/constantFunction";
import _helper from "../../_helper";
export default function (ComposedComponent) {
  class Authentication extends Component {
    componentWillMount() {
      if (!this.props.authenticated) {
        // this.context.router.history.push('/login');
      }
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) {
        // this.context.router.history.push('/login');
      }
    }

    render() {
      let { location, authenticated } = this.props;
      return authenticated && location.pathname === "/login" ? (
        <Redirect to={redirectLocation(_helper.getRole())} />
      ) : (
        <ComposedComponent {...this.props} />
      );
    }
  }

  function mapStateToProps({ auth }) {
    return { authenticated: auth.authenticated };
  }
  Authentication.contextTypes = {
    router: PropTypes.object,
  };

  return connect(mapStateToProps, actions)(Authentication);
}
