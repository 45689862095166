import axios from 'axios';

const config = {
  env: process.env.REACT_APP_ENV || 'local',
  // baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:4007'
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'https://testing.river-pay.net'
}

axios.defaults.baseURL = config.baseURL;

config.axios = axios;
export default config;
