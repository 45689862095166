import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import staticMessages from "./utils/static_messages";
import {
  UserRoles,
  ROLE,
  offSetObject,
  defaultTimezone,
} from "./utils/constants";
import moment from "moment-timezone";

const getYmdHis = (date, flag) => {
  let timeZone =
    flag && flag.profile && flag.profile.length && flag.profile[0].time_zone
      ? flag.profile[0].time_zone.timeZone
      : "America/Metlakatla";
  let ndate = moment(date).tz(timeZone).format("DD.MM.YYYY HH:mm:ss");
  return ndate;
};
 
const getOffset = (timeZone) => {
  let offset = moment.tz(timeZone).utcOffset() / 60;
  return offset;
};

const getTimeZone = (date) => {
  date = new Date(date);
  let timezone = date.toString();
  timezone = timezone.split(" ");
  timezone = timezone[5];
  return timezone;
};

const setNumberToTwoDecimal = (num) => num.toFixed(2);

const SplitString = (string) => {
  let s = "";
  if (string && string.length > 0) {
    let count = 0;
    for (let i = 0; i < string.length; i++) {
      count = count + 1;
      if (count % 2 === 0 && count !== string.length) {
        s += string[i];
        s += "-";
      } else {
        s += string[i];
      }
    }
    return s;
  }
};

const ConfirmMessage = (obj) => {
  confirmAlert({
    title: staticMessages.SubmitConfirm,
    message: obj.message,
    buttons: obj.buttons,
  });
};

const getBadgeJackpot = (status) => {
  return status === "Red"
    ? "label label-danger"
    : status === "Silver"
    ? "label label-secondary"
    : status === "Platinum"
    ? "label label-info"
    : status === "Gold"
    ? "label label-warning"
    : status === "Diamond"
    ? "label label-success"
    : "primary";
};

const getStatusofJackpot = (status) => {
  return status === "Red"
    ? "Red"
    : status === "Silver"
    ? "Silver"
    : status === "Platinum"
    ? "Platinum"
    : status === "Gold"
    ? "Gold"
    : status === "Diamond"
    ? "Diamond"
    : "primary";
};

const getRole = () => {
  return localStorage.getItem(ROLE);
};

const getRoleOption = () => {
  return getRole() === UserRoles.ADMIN
    ? [UserRoles.ADMIN, UserRoles.SHOP, UserRoles.DISTRIBUTOR]
    : getRole() === UserRoles.DISTRIBUTOR
    ? [UserRoles.SHOP, UserRoles.DISTRIBUTOR]
    : getRole() === UserRoles.SHOP
    ? [UserRoles.ADMIN, UserRoles.SHOP, UserRoles.DISTRIBUTOR]
    : [UserRoles.ADMIN, UserRoles.SHOP, UserRoles.DISTRIBUTOR];
};

// const renderAlert = (responseMessage, responseStatus) => {
//   if (responseMessage) {
//     if (responseStatus === 200) {
//       return (
//         <div className="alert alert-success">
//           <strong>Success! </strong>
//           {responseMessage}
//         </div>
//       );
//     } else {
//       return (
//         <div className="alert alert-danger">
//           <strong>Opps! </strong>
//           {responseMessage}
//         </div>
//       );
//     }
//   }
// };

const Greet = ({
  name,
  printRequest,
  componentRefernece,
  result,
  moveToUrl,
  user,
}) => {
  return (
    <div onClick={printRequest}>
      {" "}
      {name + " "}
      {user && user.enabled ? (
        <i
          className="fa fa-print"
          onClick={(e) => {
            e.preventDefault();
            moveToUrl(result._id);
          }}
        ></i>
      ) : (
        ""
      )}
    </div>
  );
};

const getExactAmount = (amount) => {
  amount = amount * 100;
  amount = Math.floor(amount) / 100;
  if (amount % 1 === 0) {
    amount = amount.toFixed(2);
  } else if (amount.toString().split(".")[1].length === 1) {
    amount = amount.toFixed(2);
  }
  return amount;
};

const getMinutes = (user) => {
  let current = new Date();
  let wallet = user && user.wallet.length && user.wallet[0];
  let minutes =
    wallet && wallet.wager_bonus_time
      ? (current - new Date(wallet.wager_bonus_time)) / 60000
      : false;
  return minutes;
};

export const getOffset1 = (login_user) => {
  let filteredArray = {};
  let offSetArray = login_user && login_user.title.split(/[()]+/);
  let offSet = offSetArray && offSetArray[1].split(" ");
  if (offSet) {
    filteredArray = offSetObject.filter((obj) => {
      return obj._id === offSet[1];
    });
  }
  return filteredArray.length && filteredArray[0];
};

export const accountHistory = (amount, bb) => {
  let condition = amount - bb;
  let amountReturn =
    condition === 0
      ? parseFloat(amount).toFixed(2)
      : `${parseFloat(bb).toFixed(2)} + ${parseFloat(condition).toFixed(2)}`;
  return amountReturn;
};

export const getProfit = (deposite, redeem, reverse) => {
  let newDep = deposite ? deposite : 0;
  let newRedeem = redeem ? redeem : 0;
  let newReverse = reverse ? reverse : 0;

  let profit = newDep - (Math.abs(newRedeem) + Math.abs(newReverse));
  return parseFloat(profit).toFixed(2);
};

export const getPayout = (deposite, reverse, redeem) => {
  let newDep = deposite ? deposite : 0;
  let newRedeem = redeem ? redeem : 0;
  let newReverse = reverse ? reverse : 0;
  let pay = (newRedeem + Math.abs(newReverse)) / newDep;
  let payOut = isNaN(pay) || !isFinite(pay) ? 0 : (pay * 100).toFixed(2);
  return Math.abs(payOut);
};

export const filterTimezone = (date) => {
  let date1 = new Date(date).toString();
  date1 = date1.replace(/\+/g, " ");
  date1 = date1.replace(/\-/g, " ");
  return date1;
};

export const setDefaultDateTime = (date, day, user) => {
  let dateWithLocalZone, filterCosnt, dateWithoutZone, localZone;
  let timezone =
    user && user.profile.length && user.profile[0].time_zone
      ? user.profile.length && user.profile[0].time_zone.timeZone
      : defaultTimezone;
  dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss.SSS");
  localZone = moment(dateWithoutZone).format("Z");

  if (day === 0) {
    dateWithLocalZone = [dateWithoutZone, localZone].join("");
  } else {
    filterCosnt =
      day === 7 ? "week" : day === 30 ? "month" : day === -1 ? "0" : false;
    dateWithLocalZone = filterCosnt
      ? [
          moment
            .tz(date, timezone)
            .clone()
            .startOf(filterCosnt)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS"),
          localZone,
        ].join("")
      : [
          moment
            .tz(date, timezone)
            .subtract(day, "d")
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss.SSS"),
          localZone,
        ].join("");
  }
  return dateWithLocalZone;
};

export const setDateTime = (date, user) => {
  let timezone =
    user && user.profile.length && user.profile[0].time_zone
      ? user.profile.length && user.profile[0].time_zone.timeZone
      : defaultTimezone;
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const otherZone = moment.tz(date, timezone).format("Z");
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("");
  return moment(dateWithOtherZone).utc().format();
};

export const initialDateTime = (date, day, user) => {
  let timezone =
    user && user.profile.length && user.profile[0].time_zone
      ? user.profile.length && user.profile[0].time_zone.timeZone
      : defaultTimezone;
  let dateString;
  if (day) {
    dateString = moment.tz(date, "MM/DD/YYYY h:mm:ss A", timezone)
      .startOf("week")
      .utc()
      .format();
    // dateString = moment
    //   .tz(date, timezone)
    //   .clone()
    //   .startOf("week")

    //   .utc()
    //   .toDate();
  } else {
    // dateString = moment.tz(date, timezone).utc().toDate();
    dateString = moment.tz(date, "MM/DD/YYYY h:mm:ss A", timezone).utc().format();
  }
  return dateString;
};

const updatedBalance = (user) => {
  let balance = {
    chargable_balance:
      document.getElementById(`wxa_${user._id}`) &&
      parseFloat(document.getElementById(`wxa_${user._id}`).innerText),
    wager_balance:
      document.getElementById(`wga_${user._id}`) &&
      parseFloat(document.getElementById(`wga_${user._id}`).innerText),
  };
  return balance;
};

const twoDecimalConversion = (value) => {
 
  return Number(value.toString().match(/^\d+(?:\.\d{0,2})?/)).toFixed(2)
  ;
};

export default {
  getRole,
  getYmdHis,
  getTimeZone,
  SplitString,
  ConfirmMessage,
  getStatusofJackpot,
  getBadgeJackpot,
  setNumberToTwoDecimal,
  getRoleOption,
  Greet,
  getExactAmount,
  getOffset,
  accountHistory,
  getProfit,
  getPayout,
  getMinutes,
  initialDateTime,
  updatedBalance,
  twoDecimalConversion
};
