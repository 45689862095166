import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import {Redirect} from 'react-router-dom';


class Logout extends Component {
    componentWillMount() {
        this.props.signUserOut()
    }
    render() {
     return (<Redirect to={'/login'}/>)
    }
        
}

export default connect(null, actions)(Logout)